.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: 5vh;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    background: #ffffff;
}

.username-text {
    display: none;
}

.header-title {
    cursor: pointer;
}

.explore-button {
    color: #00a278 !important;
}

@media screen and (min-width: 768px) {
    .username-text {
        display: block;
    }
}