@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.footer {
    height: 25vh;
    background-color: #042C22;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-text {
    color: #fff;
    opacity: 0.5;
    text-align: center;
    font-weight: 300;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .footer-text {
        width: 40%;
    }
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: 5vh;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    background: #ffffff;
}

.username-text {
    display: none;
}

.header-title {
    cursor: pointer;
}

.explore-button {
    color: #00a278 !important;
}

@media screen and (min-width: 768px) {
    .username-text {
        display: block;
    }
}
.content {
  background-image: url(/static/media/hero_image.54c8073c.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.form {
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  min-width: 80%;
  margin: 2rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.title {
  color: #00a278;
}

.secondary-action {
  padding: 1rem 0;
}

.link {
  font-weight: 700;
  text-decoration: none;
  color: #45c09f;
}

.link:focus {
  color: #00a278;
}

.link:active {
  color: #45c09f;
}

.link:visited {
  color: #45c09f;
}

.button {
    background-color: #00a278 !important;
}

.button:hover {
    background-color: #00845c !important;
}

@media screen and (min-width: 768px) {
  .content{
    justify-content: flex-end;
  }
  .form {
    min-width: 24rem;
  }
}

.content {
    background-image: url(/static/media/hero_image.54c8073c.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    height: 70vh;
}

.form {
    background-color: white;
    border-radius: 10px;
    padding: 2rem;
    min-width: 80%;
    margin: 2rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.title {
    color: #00a278;
}

.secondary-action {
    padding: 1rem 0;
}

.link {
    font-weight: 700;
    text-decoration: none;
}

.link:focus {
    color: #00a278;
}

.link:active {
    color: #45c09f;
}

.link:visited {
    color: #45c09f;
}

.button {
    background-color: #00a278 !important;
}

.button:hover {
    background-color: #00845c !important;
}

@media screen and (min-width: 768px) {
    .content {
        justify-content: flex-end;
    }
    .form {
        min-width: 24rem;
    }
}
.search {
  min-width: 24rem;
  position: relative;
  left: 4rem;
}

.hero {
  background-image: url(/static/media/hero_image.54c8073c.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-grid {
  width: 100%;
}

.hero-heading {
  color: #fff;
  font-size: 1.5rem;
  width: 80%;
  position: relative;
  left: 1rem;
}

.hero-highlight {
  color: #212121;
  font-weight: 700;
  background: #ffb825;
  border-radius: 5px;
  padding: 0.2rem;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  font-weight: bold;
}

.search-mobile {
  display: block !important;
  margin-bottom: 0.5rem !important;
}

.search-desktop {
  display: none !important;
}

.cart-area{
  background-color: #E9F5E1;
}

@media screen and (min-width: 768px) {
  .hero {
    justify-content: flex-end;
    height: 20rem;
    padding-right: 8rem;
  }
  .hero-heading {
    position: static;
    font-size: 3rem;
    width: 50%;
  }
  .search-mobile {
    display: none !important;
  }
  .search-desktop {
    display: block !important;
  }
}

.card {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-actions {
    margin-bottom: 1rem;
}

.card-button {
    text-transform: none;
    font-weight: 600;
}

.cost-text{
    font-weight:bold !important;
}
.cart {
    background-color: #ffffff;
    border-radius: 4px;
    margin: 0.5rem;
}

.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}

.cart-footer {
    padding: 1rem;
}

.image-container {
    max-height: 10rem;
    max-width: 8rem;
}

.checkout-btn {
    text-transform: none;
    font-weight: 700;
    font-size: 1.1rem;
}

.empty-cart-icon {
    width: 3rem !important;
    height: 3rem !important;
    color: #aaa;
}

.cart-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#add-new-btn {
    text-transform: none;
    font-weight: 700;
    margin: 1rem 0;
}

.address-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem 0;
    border-radius: 5px;
    cursor: pointer;
    color: #3C3C3C;
}

.shipping-container {
    padding: 2rem 1rem;
}

.selected {
    border: 1px solid #00A278;
    background-color: #E9F5E1;
}

.not-selected {
    border: 1px solid #ddd;
}

p {
    color: #3C3C3C;
}

@media screen and (min-width: 768px) {
    .shipping-container {
        padding: 2rem 4rem;
    }
}
p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 1rem;
}

.greeting-container {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    background-color: #DCFFE4;
}

#balance-overline {
    font-weight: 700;
    margin: 1rem 0 0.5rem 0;
}

#balance {
    font-weight: 700;
    font-size: 1.25rem;
}

#continue-btn {
    font-weight: 700;
    text-transform: none;
}

@media screen and (min-width: 768px) {
    .greeting-container {
        padding: 4rem;
    }
}
